import { Controller } from '@hotwired/stimulus';
import Flickity from 'flickity';
import 'flickity/dist/flickity.css';

export default class extends Controller {
  connect() {
    this.initializeCarousel();
    this.element.addEventListener('htmx:afterSwap', this.handleAfterSwap.bind(this));
  }

  handleAfterSwap() {
    this.initializeCarousel();
  }

  initializeCarousel() {
    const carousels = this.element.querySelectorAll('.carousel-explore');
    if (carousels.length === 0) {
      console.log("Nema .carousel-explore elemenata");
      return;
    }
  
    carousels.forEach((carousel) => {
      carousel.querySelectorAll('img[src=""]').forEach(img => {
        img.src = 'https://via.placeholder.com/1000x600.png?text=No+Picture';
      });
    });
  
    carousels.forEach((carousel, index) => {
      const flickityInstance = new Flickity(carousel, {
        cellAlign: 'left',
        contain: true,
        wrapAround: true,
        pageDots: true,
        prevNextButtons: true,
        draggable: true
      });
  
      imagesLoaded(carousel, () => {
        flickityInstance.resize();
      });
  
      flickityInstance.on('ready', () => {
        flickityInstance.resize();
      });
    });
  }
}
