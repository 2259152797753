import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["popupContainer", "popupContent"];

    connect() {
        this.element.addEventListener("htmx:afterSwap", this.handleAfterSwap.bind(this));
    }

    open(event) {
        event.preventDefault();
        this.popupContainerTarget.style.display = "flex";
    }

    close(event) {
        event.preventDefault();
        this.popupContainerTarget.style.display = "none";
        this.popupContentTarget.innerHTML = "";
    }

    handleAfterSwap(event) {
        if (event.detail.target === this.popupContentTarget) {
        }
    }
}
